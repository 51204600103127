import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CustomModal,
  formatterPeso,
  serviceName,
  CircularProgress,
  Box
}
  from "@enerbit/base";
import { forwardRef, Fragment, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { detailPayment, isLoadingDetail, paymentSelect } from "../../features/payments/payment";
import { CustomModalTypes } from "../../models/paymentState";

const DetailForm = forwardRef((props, ref) => {
  const detailPaymentModal = useRef<CustomModalTypes>(null);
  const payment = useSelector(paymentSelect);
  const loading = useSelector(isLoadingDetail);
  const detailsPaymentsInvoices = useSelector(detailPayment);

  useImperativeHandle(ref, () => ({
    changeModal() {
      if (detailPaymentModal.current) {
        detailPaymentModal.current.changeModal();
      }
    },
  }));

  return (
    <CustomModal maxWidth="md" ref={detailPaymentModal}
      onClose={() => { }}
      dialogContent={
        <Fragment>
          <Box className="Box-title-detail">Detalle facturas del pago {payment.substring(0, 8)}</Box>
          {
            loading ?
              <Box className="Loading-flex">
                <CircularProgress />
              </Box>
              : (
                <Box className="Container-service-group">
                  {detailsPaymentsInvoices?.service_groups &&
                    detailsPaymentsInvoices.service_groups.map((row, index) => (
                      <div key={index} className="Container-payment-modal">
                        <Box className="Details-titles" key={index}>
                          <Box
                            sx={{ fontSize: "24px", color: "white" }}>
                            {row.personalization ?? row.service_account_id.substring(0, 8)}
                          </Box>
                          <Box
                            sx={{ fontSize: "24px", color: "white" }}>
                            {formatterPeso.format(row.legal_payable_amount)}
                          </Box>
                        </Box>
                        <TableContainer component={Paper} sx={{ boxShadow: "none", padding: "1rem" }}>
                          <Table className="Table-general" aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Grupo de servicio</TableCell>
                                <TableCell align="left">Dirección</TableCell>
                                <TableCell align="left">Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.services.map((item, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                  >
                                    {serviceName[item.service_type_name]}
                                  </TableCell>
                                  <TableCell>{item.address}</TableCell>
                                  <TableCell>{formatterPeso.format(item.legal_payable_amount)}</TableCell>
                                </TableRow>
                              ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ))}
                </Box>
              )
          }
        </Fragment>
      }
    />
  );
});

export default DetailForm;
